


















































































































































































































































.table > thead > tr > th, .el-table__body > thead > tr > th {
  font-size: 15px;
  text-transform: uppercase;
  text-align: center;
  color: black;
  font-weight: 600;
}

.table tbody td:first-child, .table thead th:first-child {
  padding: 10px;
}

tr {
  text-align: center;
}

.card .table tbody td:last-child, .card .table thead th:last-child {
  display: revert;
}
